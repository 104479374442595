// src/pages/HomePage.js or a separate JS file

import React, { useEffect } from 'react';
import './Home.css';
import Navbar from '../../components/Navbar';
import SplineScene from '../../components/3d/SplineScene';


const HomePage = () => {


    return (
        <div className='home-section w3-animate-opacity w3-display-container'>
            <SplineScene />
            <div className="w3-display-middle">
                
                <h3>  System under maintanance, We'll be back online soon </h3>

            </div>
          

        </div>
    );
}

export default HomePage;


