// src/pages/CustomerLoginPage.js
import React from 'react';
import Navbar from '../../components/Navbar';
import LoginForm from '../../components/LoginForm';

const CustomerLoginPage = () => (
  <div className='w3-animate-opacity'>
    <Navbar />
    <h1>Customer Login</h1>
    <LoginForm />
  </div>
);

export default CustomerLoginPage;
