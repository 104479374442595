import React, { useEffect } from 'react';
import { Application } from 'https://esm.sh/@splinetool/runtime';

const SplineScene = () => {
  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    const app = new Application(canvas);
    
    // Set canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    app.load('https://prod.spline.design/IIDgiku-GASS-44V/scene.splinecode').then(() => {
      console.log('Scene loaded');
    }).catch((error) => {
      console.error('Error loading scene:', error);
    });

    return () => {
      // Cleanup if needed when the component unmounts
      app.dispose();
    };
  }, []);

  return (
    <canvas id="canvas3d" className="canvas" style={{ position: 'absolute', top: 0, left: 0 }}></canvas>
  );
};

export default SplineScene;
