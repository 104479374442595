// src/pages/StaffLoginPage.js
import React from 'react';
import Navbar from '../../components/Navbar';
import LoginForm from '../../components/LoginForm';

const StaffLoginPage = () => (
  <div>
    <Navbar />
    <h1>Staff Login</h1>
    <LoginForm />
  </div>
);

export default StaffLoginPage;
