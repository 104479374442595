import React, { useEffect, useState } from 'react';
import './announcements.css';
import Navbar from '../../../components/Navbar';

// Define your announcements array with date
const announcements = [
  {
    id: 1,
    title: 'Lossless Youths 1',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=1',
    date: '2024-08-01T10:00:00Z'
  },
  {
    id: 2,
    title: 'Estrange Bond 2',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=2',
    date: '2024-08-02T14:30:00Z'
  },
  {
    id: 3,
    title: 'The Gate Keeper 3',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=3',
    date: '2024-08-03T16:45:00Z'
  },
  {
    id: 4,
    title: 'Last Trace Of Us 4',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=4',
    date: '2024-08-04T11:20:00Z'
  },
  {
    id: 5,
    title: 'Urban Decay 5',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=5',
    date: '2024-08-05T09:10:00Z'
  },
  {
    id: 6,
    title: 'The Migration 6',
    content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.',
    image: 'https://unsplash.it/1920/1080?random=6',
    date: '2024-08-06T13:55:00Z'
  },
];
const AnnouncementPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = announcements.length;
  let slideInterval;

  const showSlide = (n) => {
    setCurrentSlide((prevSlide) => (n + totalSlides) % totalSlides);
  };

  const nextSlide = () => showSlide(currentSlide + 1);

  const prevSlide = () => showSlide(currentSlide - 1);

  const startSlideShow = () => {
    slideInterval = setInterval(nextSlide, 5000); // Change slide every 4 seconds
  };

  const stopSlideShow = () => clearInterval(slideInterval);

  useEffect(() => {
    startSlideShow();

    // Clean up interval on component unmount
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  // Function to format date
  const formatDate = (dateStr) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateStr).toLocaleDateString(undefined, options);
  };

  // Click handler for share icon
  const handleShare = (id) => {
    alert(`Share clicked for announcement ${id}`);
  };

  // Click handler for attachment icon
  const handleAttachment = (id) => {
    alert(`Attachment clicked for announcement ${id}`);
  };

  return (
    <div>
      <Navbar />
      <div className="announcement-page w3-container w3-animate-opacity">
        <h4>Recent ({totalSlides})</h4>
        <div className="slider-container">
          {announcements.map((announcement, index) => (
            <div
              key={announcement.id}
              className={`slide ${index === currentSlide ? 'active' : ''} bg-color-${(index % 3) + 1}`}
            >
              <div className="text-box">
                <h2>{announcement.title}</h2>
                <p>{announcement.content}</p>
                <div className="info-container">
                  <p className="date-posted">{formatDate(announcement.date)}</p>
                  <i
                    className="fa-solid fa-share"
                    onClick={() => handleShare(announcement.id)}
                  ></i>
                  <i
                    className="fa-solid fa-paperclip"
                    onClick={() => handleAttachment(announcement.id)}
                  ></i>
                </div>
                <a href="#" className="btn">Read More</a>
              </div>
              <img src={announcement.image} alt={announcement.title} />
            </div>
          ))}

          <i className="arrow fa-solid fa-arrow-left" id="prevBtn" onClick={prevSlide}></i>
          <i className="arrow fa-solid fa-arrow-right" id="nextBtn" onClick={nextSlide}></i>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPage;