// src/pages/CustomerLoginPage.js
import React from 'react';
import Navbar from '../../../components/Navbar';
import LoginForm from '../../../components/LoginForm';

const ServicePage = () => (
  <div>
    <Navbar />
    <h1>Announcements</h1>
    <LoginForm />
  </div>
);

export default ServicePage;
