import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import logo from './../components/imgs/icons/logo-green-bg.png'; 

const Navbar = () => {
  const [navbarHeight, setNavbarHeight] = useState(70); // Initial height
  const minHeight = 60; // Minimum height
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility

  const location = useLocation(); // Get the current route
  const currentPath = location.pathname; // Get the current path

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newHeight = Math.max(minHeight, 70 - scrollY); // Reduce height based on scroll
    setNavbarHeight(newHeight);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar state
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getLinkClass = (path) => {
    return currentPath === path ? 'navbar-item active' : 'navbar-item';
  };

  return (
    <nav className="navbar" style={{ height: `${navbarHeight}px` }}>

      <div className="w3-sidebar w3-animate-left w3-bar-block" style={{ display: isSidebarOpen ? 'block' : 'none' }} id="side-bar">
        <button className="w3-bar-item w3-button w3-hide-large" onClick={toggleSidebar}>&#9776;</button>
        
        <p>
          <Link to="/" className={getLinkClass('/')}>
            
             home
          </Link>
        </p>

        <p>
          <Link to="/announcements" className={getLinkClass('/announcements')}>
            <i className="fa-solid fa-tape"></i> 
             Announcement
          </Link>
        </p>
        <p>
          <Link to="/blog" className={getLinkClass('/blog')}>
            <i className="fa-solid fa-blog"></i> 
             Blog
          </Link>
        </p>
        <p>
          <Link to="/services" className={getLinkClass('/services')}>
            <i className="fa-solid fa-cart-flatbed-suitcase"></i> 
             Services
          </Link>
        </p>
        <p>
          <Link to="/about-us" className={getLinkClass('/about-us')}>
            <i className="fa-solid fa-people-group"></i> 
             About us
          </Link>
        </p>

        <div className="dropdown navbar-item">
          <div className="w3-dropdown-hover">
            <span className="navbar-item">
              <i className="fa-solid fa-chevron-down"></i>
              Accounts
            </span>
            <div className="w3-dropdown-content w3-bar-block w3-animate-opacity w3-round-large">
              <p><Link to="/staff-login" className={getLinkClass('/staff-login')}><i className="fa-solid fa-users"></i> Staff Login</Link></p>
              <p><Link to="/customer-login" className={getLinkClass('/customer-login')}><i className="fa-solid fa-person-shelter"></i> Customer Login</Link></p>
              <p><Link to="/system-admin" className={getLinkClass('/system-admin')}><i className="fa-solid fa-user-tie"></i> System Admin</Link></p>
              <p><Link to="/super-admin" className={getLinkClass('/super-admin')}><i className="fa-solid fa-people-group"></i> Super Admin</Link></p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="navbar-brand">
        <div className='sidebar'>
          <span className='sidebar-menu-btn' onClick={toggleSidebar}>&#9776;</span>
        </div>
        <Link to="/">
          <img className='nav-logo' src={logo} alt="Profile" />
        </Link>
      </div>
      
      <div className="navbar-menu">

      <Link to="/" className={getLinkClass('/')}>
         
          home
        </Link>
        <Link to="/announcements" className={getLinkClass('/announcements')}>
          <i className="fa-solid fa-tape"></i>
          Announcement
        </Link>

        <Link to="/blog" className={getLinkClass('/blog')}>
          <i className="fa-solid fa-blog"></i>
          Blog
        </Link>

        <Link to="/services" className={getLinkClass('/services')}>
          <i className="fa-solid fa-cart-flatbed-suitcase"></i>
          Services
        </Link>

        <Link to="/about-us" className={getLinkClass('/about-us')}>
          <i className="fa-solid fa-people-group"></i>
          About us
        </Link>

        <div className="dropdown navbar-item">
          <div className="w3-dropdown-hover">
            <span className="navbar-item">
              <i className="fa-solid fa-chevron-down"></i>
              Accounts
            </span>
            <div className="w3-dropdown-content w3-bar-block w3-animate-opacity w3-round-large">
              <p><Link to="/staff-login" className={getLinkClass('/staff-login')}><i className="fa-solid fa-users"></i> Staff Login</Link></p>
              <p><Link to="/customer-login" className={getLinkClass('/customer-login')}><i className="fa-solid fa-person-shelter"></i> Customer Login</Link></p>
              <p><Link to="/system-admin" className={getLinkClass('/system-admin')}><i className="fa-solid fa-user-tie"></i> System Admin</Link></p>
              <p><Link to="/super-admin" className={getLinkClass('/super-admin')}><i className="fa-solid fa-people-group"></i> Super Admin</Link></p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
