// src/pages/SystemAdminPage.js
import React from 'react';

const SystemAdminPage = () => (
  <div>
    <h1>System Admin Page</h1>
    {/* System admin details */}
  </div>
);

export default SystemAdminPage;
