// src/pages/SuperAdminPage.js
import React from 'react';

const SuperAdminPage = () => (
  <div>
    <h1>Super Admin Page</h1>
    {/* Super admin details */}
  </div>
);

export default SuperAdminPage;
