// src/pages/StaffAccountPage.js
import React from 'react';

const StaffAccountPage = () => (
  <div>
    <h1>Staff Account</h1>
    {/* Staff account details */}
  </div>
);

export default StaffAccountPage;
