// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import AnnouncementPage from './pages//home/announcements/announcements';
import BlogPage from './pages/home/blog/blog';
import ServicePage from './pages/home/services/services';
import AboutPage from './pages/home/about/about';

import StaffLoginPage from './pages/staff/StaffLoginPage';
import StaffAccountPage from './pages/staff/StaffAccountPage';
import CustomerLoginPage from './pages/customer/CustomerLoginPage';
import CustomerAccountPage from './pages/customer/CustomerAccountPage';
import SystemAdminPage from './pages/admin/SystemAdminPage';
import SuperAdminPage from './pages/superAdmin/SuperAdminPage';

const App = () => (
  <Router>
    <Routes>
    <Route path="/" element={<HomePage />} />
      <Route path="/announcements" element={<AnnouncementPage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/services" element={<ServicePage />} />
      <Route path="/about-us" element={<AboutPage />} />

      <Route path="/staff-login" element={<StaffLoginPage />} />
      <Route path="/staff-account" element={<StaffAccountPage />} />
      <Route path="/customer-login" element={<CustomerLoginPage />} />
      <Route path="/customer-account" element={<CustomerAccountPage />} />
      <Route path="/system-admin" element={<SystemAdminPage />} />
      <Route path="/super-admin" element={<SuperAdminPage />} />
    </Routes>
  </Router>
);

export default App;
